

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
     cartItems: [],
     totalAmount: 0,
     totalQuantity: 0
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
    addItem:(state, action)=>{
        const newItem = action.payload;
        const existingItem = state.cartItems.find(
          (item)=> item.id === newItem.id);
          state.totalQuantity+=newItem.quantity;
          if(!existingItem){
            console.log(newItem.quantity);
            state.cartItems.push({
                id: newItem.id,
                productName: newItem.productName,
                dsecription: newItem.description,
                imgUrl: newItem.imgUrl,
                price: newItem.price,
                quantity: newItem.quantity,
                totalPrice: newItem.price
            })
          }
          else{
            existingItem.quantity+=newItem.quantity;
            existingItem.totalPrice = Number(existingItem.totalPrice) + Number
            (newItem.price)
          }  

          state.totalAmount = state.cartItems.reduce((total, item)=> total+ Number(item.price)* Number(item.quantity),0)

          // console.log(state.totalQuantity);
          // console.log(state.cartItems);
    },
    
        deleteItem:(state, action)=>{
          const id = action.payload;
          const existingItem = state.cartItems.find((item)=>item.id ===id)

          if(existingItem){
            
              state.cartItems = state.cartItems.filter(item=> item.id !== id)
              console.log(state.totalQuantity);
              console.log(existingItem.quantity);
              let k= existingItem.quantity;
              state.totalQuantity = state.totalQuantity - k;
          }
          state.totalAmount = state.cartItems.reduce((total, item)=> total+ Number(item.price)* Number(item.quantity),0)
        },
      },

});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;