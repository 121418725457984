import React from 'react'
import useGetData from '../custom-hooks/useGetData';
import { db } from '../firebase.config';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Messages = () => {

  const navigate= useNavigate();
  const { data: productsData } = useGetData("contact");
  const filteredProducts = productsData.filter(item=>item.Solved==='Not Solved')

  const deleteProduct = async id=>{
    await deleteDoc(doc(db, 'contact',id));
    alert("Item deleted")
  }
  
  
  
  const [changedStatus, setChangedStatus] = useState('');

  const handleChange= async ( id)=>{
    
     console.log(changedStatus)
     const docRef= doc(db,'contact', id)
      try {
          await updateDoc(docRef, { Solved: changedStatus }); // Update the attribute with the new value
          alert('Status updated successfully!');
          navigate('/dashboard/messages')
        } catch (error) {
          console.error('Error updating document attribute: ', error);
        }
       
      
  }


 


  return (
    <div>
  <section class="cart_area section_padding">
    <div class="container">
      <div class="cart_inner">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                {/* <th scope="col">Image</th> */}
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile</th>
                <th scope="col">Date</th>
                <th scope="col">Subject</th>
                <th scope="col">Message</th>
                <th scope="col">Status</th>
                {/* <th scope="col">Mrp</th>
                <th scope="col">Price</th>
                <th scope="col">Delete</th> */}
              </tr>
            </thead>
            <tbody>
             {
              productsData.map(item=>(
                <tr key={item.id}>
                {/* <td>
                  <div class="media">
                    <div class="d-flex">
                      <img src={item.imgUrl} alt="" />
                    </div> */}
                    {/* <div class="media-body">
                      <p>{item.title}</p>
                    </div> */}
                  {/* </div>
                </td> */}
                <td>
                  {item.name}
                </td>
                <td>
                  {item.email}
                </td>
                <td>
                  {item.mobileNo}
                </td>
                <td>
                  {item.createdAt.slice(0,10)}
                </td>
                <td>
                  {item.subject}
                </td>
                <td>
                 {item.message}
                </td>
                <td>
                <select class="form-select" aria-label="Default select example" value={item.status} onChange={e=>setChangedStatus(e.target.value)}
                                        >
                                          
                                          <option selected>{item.Solved}</option>
                                          <option value="Solved">Solved</option>
                                          <option value="Not Solved">Not Solved</option>
                                         
                                          
                                        </select>
                </td>
                {/* <td>
                  {item.mrp}
                </td>
                <td>
                  {item.price}
                </td> */}
                <td>
                  <button onClick={()=>{
                    deleteProduct(item.id);
                  }}>Delete</button></td>
                  <td>
                  <button onClick={()=>{
                    handleChange(item.id);
                  }}>Save</button>
                  </td>
              </tr>
              ))
             }
             
             
              
            </tbody>
          </table>
          
        </div>
      </div>
      </div>
  </section>
    </div>
  )
}

export default Messages
