
import React from 'react'
// import { Form, FormGroup } from 'reactstrap';
import { useState } from 'react';
import {db, storage} from '../firebase.config'
import { ref, uploadBytesResumable, uploadBytes, getDownloadURL, getStorage } from 'firebase/storage';
import { collection, addDoc, updateDoc, doc, getDoc,} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom';
import { getFirestore, Timestamp } from 'firebase/firestore';
// import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';


const AddProducts =  () => {

    const [enterTitle, setEnterTitle] = useState('')
    const [enterDescription, setEnterDescription] = useState('')
    const [enterBrand, setEnterBrand] = useState('')
    const [enterCategory, setEnterCategory] = useState('')
    const [enterPrice, setEnterPrice] = useState('')
    const [enterMrp, setEnterMrp] = useState('')
    const [enterSubCategory, setEnterSubCategory] = useState('')
    const [enterWrranty, setEnterWarranty] = useState('')
    const [enterProductImg, setEnterProductImg] = useState(null)
    
    const [loading, setLoading] = useState(false)
    const [enterTrending, setEnterTrending] = useState(false)
    const [enterInventory, setEnterInventory] = useState('')
    const navigate = useNavigate()
    // const storage = firebase.storage();
    // const firestore = firebase.firestore();
    const timestamp = Timestamp.fromDate(new Date());


    const toggle = ()=>{
        if(enterTrending==false){
            setEnterTrending(true)
        }
        else{
            setEnterTrending(false)
        }
    }
    
    const [imageUrl1, setImageUrl1] = useState('');
    const [imageUrl2, setImageUrl2] = useState('');
    const [imageUrl3, setImageUrl3] = useState('');

    const handleImageUpload1 = async (e) => {
        setLoading(true);
        const file = e.target.files[0];
        const storage = getStorage();
        const storageRef = ref(storage, 'images/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        try {
          await uploadTask.on(
            'state_changed',
            null,
            (error) => {
              console.error(error);
              setLoading(false);
            },
            async () => {
              const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
              setImageUrl1(downloadUrl);
              setLoading(false);
            }
          );
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };

      const handleImageUpload2 = async (e) => {
        setLoading(true);
        const file = e.target.files[0];
        const storage = getStorage();
        const storageRef = ref(storage, 'images/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        try {
          await uploadTask.on(
            'state_changed',
            null,
            (error) => {
              console.error(error);
              setLoading(false);
            },
            async () => {
              const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
              setImageUrl2(downloadUrl);
              setLoading(false);
            }
          );
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };

      const handleImageUpload3 = async (e) => {
        setLoading(true);
        const file = e.target.files[0];
        const storage = getStorage();
        const storageRef = ref(storage, 'images/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        try {
          await uploadTask.on(
            'state_changed',
            null,
            (error) => {
              console.error(error);
              setLoading(false);
            },
            async () => {
              const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
              setImageUrl3(downloadUrl);
              setLoading(false);
            }
          );
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };
        
      
    const handleUpload = async () => {
        setLoading(true);
    //     const files = imageUpload;
        
    //     const urls = [];
     try{
    //     // Loop through selected files and upload them to Firebase Storage
    //     for (const file of files) {
    //       const storageRef = ref(storage, `images/${file.name}`);
    //       await uploadBytes(storageRef, file);
    //       const downloadUrl = await getDownloadURL(storageRef);
    //       urls.push(downloadUrl);
    //     }
    
        // Add the image download URLs to a new document in Firestore
        const docRef = collection(db, 'products');
        await addDoc(docRef, { title: enterTitle,
            brand: enterBrand,
            description: enterDescription,
            category: enterCategory,
            subCategory: enterSubCategory,
            mrp: enterMrp,
            price: enterPrice,
            warranty: enterWrranty,
            trending: enterTrending,
            inventory: enterInventory,
            createdAt: timestamp,
            updatedAt: timestamp,
            img1: imageUrl1,
            img2: imageUrl2,
            img3:imageUrl3,
         });
        }catch{
            console.log("error");
        }
        setLoading(false);
        
        alert("Product Added Successfully")
        navigate("/dashboard/all-products")
      };
      
        // const addProducts = () => {
        //     console.log(imageUpload);
        //   handleUpload(imageUpload);
          
        // };
       

  return ( 
  <>
    {loading ?  <div className="container-fluid ml-2 text-center"><h3>Loading...</h3> </div> :
    <section>
        <div class="container-fluid ml-1 ">      
            <div class="row ">
                <div class="col-lg-10 col-md-8 ml-4 mr-3">
                    <div class="row align-items-center pt-md-5 mt-md-5 mb-5">
                        <div class="col-12">
                            <div class="card">
                              { loading ? <div class="card-title text-center mt-3">
                                    <h3>Loading....</h3>
                                </div> :
                                <div class="card-title text-center mt-3">
                                    <h3>Add Product</h3>
                                </div>
                                }
                                <div class="card-body">
                                    <form >
                                        <div class="form-group">
                                            <label for="productname">Product Name:</label>
                                            <input type="text" class="form-control" id="productname"
                                                placeholder="Enter Product Name" value={enterTitle} onChange={e=>setEnterTitle(e.target.value)}/>
                                            <div class="invalid-feedback">Product Name Can't Be Empty</div>
                                        </div>
                                        {/* <div class="form-group my-3">
                                            <label for="productid">Product Id:</label>
                                            <input type="text" class="form-control" id="productid"
                                                placeholder="Enter Product Id"
                                                />
                                            <div class="invalid-feedback">Product ID Can't Be Empty</div>
                                        </div> */}
                                        <div class="form-group ">
                                            <label for="productname">Brand</label>
                                            <input type="text" class="form-control" id="shortDescription"
                                                placeholder="Enter Brand Name"
                                                value={enterBrand} onChange={e=>setEnterBrand(e.target.value)}/>
                                            <div class="invalid-feedback">Product Brand</div>
                                        </div>
                                        
                                        <div class="form-group ">
                                            <label for="productname">Description:</label>
                                            <input type="text" class="form-control" id="description"
                                                placeholder="Enter Description"
                                                value={enterDescription} onChange={e=>setEnterDescription(e.target.value)}/>
                                            <div class="invalid-feedback">Description Can't Be Empty</div>
                                        </div>
                                        <div class="form-group ">
                                            <label for="productname">Warranty(in months):</label>
                                            <input type="text" class="form-control" id="description"
                                                placeholder="Enter Warranty"
                                                value={enterWrranty} onChange={e=>setEnterWarranty(e.target.value)}/>
                                            <div class="invalid-feedback">Description Can't Be Empty</div>
                                        </div>
                                        <div class="form-group ">
                                            <label for="productprice">Mrp</label>
                                            <input type="text" class="form-control" id="productprice"
                                                placeholder="Enter Mrp"
                                                value={enterMrp} onChange={e=>setEnterMrp(e.target.value)}/>
                                            <div class="invalid-feedback">Product mrp:</div>

                                        </div>
                                        <div class="form-group">
                                            <label for="productprice">Product Price:</label>
                                            <input type="text" class="form-control" id="productprice"
                                                placeholder="Enter Product Price"
                                                value={enterPrice} onChange={e=>setEnterPrice(e.target.value)}/>
                                            <div class="invalid-feedback">Product Price Can't Be Empty</div>

                                        </div>
                                        <select class="form-select my-4" aria-label="Default select example"
                                        value={enterCategory} onChange={e=>setEnterCategory(e.target.value)}>
                                          
                                          <option selected>Category</option>
                                          <option value="Crockery">Crockery</option>
                                          <option value="Mobiles">Mobiles</option>
                                          <option value="Laptop">Laptop</option>
                                          <option value="Sarees">Sarees</option>
                                          <option value="Accessories">Accessories</option>
                                        </select>
                                        <div class="form-group">
                                            <label for="productprice">Subcategory:</label>
                                            <input type="text" class="form-control" id="subcategory"
                                                placeholder="Enter Sub category"
                                                value={enterSubCategory} onChange={e=>setEnterSubCategory(e.target.value)}/>
                                            <div class="invalid-feedback">enter subcategory</div>

                                        </div>
                                        <div class="form-group ">
                                            <label for="productprice">Inventory:</label>
                                            <input type="text" class="form-control" id="productprice"
                                                placeholder="Enter Inventory"
                                                value={enterInventory} onChange={e=>setEnterInventory(e.target.value)}/>
                                            <div class="invalid-feedback">Inventory:</div>

                                        </div>

                                        <div class="form-check">
                                        <input class="form-check-input" type="checkbox"  onChange={toggle} id="flexCheckDefault"/>
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Trending
                                        </label>
                                        </div>
                                        <p>Product Images:</p>
                                        <div class="custom-file">

                                            <input type="file" class="custom-file-input my-3" id="productimage" required
                                            onChange={handleImageUpload1}/>
                                            <label class="custom-file-label" for="productimage">Choose
                                                file...</label>
                                    

                                            <div class="invalid-feedback">File Format Not Supported</div>
                                        </div>
                                        <p>Product Images:</p>
                                        <div class="custom-file">

                                            <input type="file" class="custom-file-input my-3" id="productimage" required
                                            onChange={handleImageUpload2}/>
                                            <label class="custom-file-label" for="productimage">Choose
                                                file...</label>
                                    

                                            <div class="invalid-feedback">File Format Not Supported</div>
                                        </div>
                                        <p>Product Images:</p>
                                        <div class="custom-file">

                                            <input type="file" class="custom-file-input my-3" id="productimage" required
                                            onChange={handleImageUpload3}/>
                                            <label class="custom-file-label" for="productimage">Choose
                                                file...</label>
                                    

                                            <div class="invalid-feedback">File Format Not Supported</div>
                                        </div>
                                        {/* <p>Product Image 2:</p>
                                        <div class="custom-file">

                                            <input type="file" class="custom-file-input my-3" id="productimage" required
                                            onChange={e=>imageUpload[1]=(e.target.files[0])}/>
                                            
                                            <label class="custom-file-label" for="productimage">Choose
                                                file...</label>
                                            <div class="invalid-feedback">File Format Not Supported</div>
                                        </div>
                                        <p>Product Image 3:</p>
                                        <div class="custom-file">

                                            <input type="file" class="custom-file-input my-3" id="productimage" required
                                            onChange={e=>imageUpload[2]=(e.target.files[0])}/>
                                            
                                            <label class="custom-file-label" for="productimage">Choose
                                                file...</label>
                                            <div class="invalid-feedback">File Format Not Supported</div>
                                    </div>*/}
                                     
                                           
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-dark mt-5 mx-auto d-block"  onClick={handleUpload}>Add
                                            Product</button>  

    </section>
}
    </>
  
)}

export default AddProducts
