import React from 'react'
import useGetData from '../../custom-hooks/useGetData';
import { useState } from 'react';

const TrackOrders = () => {

    const { data: productsData } = useGetData("orders");
    const [enteredEmail, setEnteredEmail] = useState('');
    const [filteredData, setFilteredData] = useState(null)
    const [found, setFound] = useState(null)
    var filteredProducts;

    const handleSearch = () => {
        filteredProducts = productsData.filter(item => item.Email === enteredEmail)
        if (filteredProducts.length > 0) {
          setFilteredData(filteredProducts[0].Status);
          setFound(true)
        } else {
          setFound(false)
          setFilteredData(null)
        }
      }

  return (
    <>
       

            <div class="breadcrumb_part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breadcrumb_iner">
                                <h2>Track Order</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contact-section section_padding">
                <div class="container">
                {/*<div class="d-none d-sm-block mb-5 pb-4">
                    <div id="map" style="height: 480px;"></div>
                    <script>
                    function initMap() {
                        var uluru = {
                        lat: -25.363,
                        lng: 131.044
                        };
                        var grayStyles = [{
                            featureType: "all",
                            stylers: [{
                                saturation: -90
                            },
                            {
                                lightness: 50
                            }
                            ]
                        },
                        {
                            elementType: 'labels.text.fill',
                            stylers: [{
                            color: '#ccdee9'
                            }]
                        }
                        ];
                        var map = new google.maps.Map(document.getElementById('map'), {
                        center: {
                            lat: -31.197,
                            lng: 150.744
                        },
                        zoom: 9,
                        styles: grayStyles,
                        scrollwheel: false
                        });
                    }
                    </script>
                    <script
                    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDpfS1oRGreGSBU5HHjMmQ3o5NLw7VdJ6I&callback=initMap">
                    </script>

                </div>*/}


                <div class="row">
                    <div class="col-12">
                    <h2 class="contact-title">Track Your Order Status</h2>
                    </div>
                    <div class="col-lg-8">
                    <form class="form-contact contact_form"  id="contactForm"  
                       >
                        <div class="row">
                       
                        <div class="col-sm-6">
                            <div class="form-group">
                            <input class="form-control" name="name" id="name" type="text" onfocus="this.placeholder = ''"
                                onblur="this.placeholder = 'Enter your name'" placeholder='Enter your name'  required/>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                            <input class="form-control" name="email" id="email" type="email" onfocus="this.placeholder = ''"
                                onblur="this.placeholder = 'Enter email address'" placeholder='Enter email address' value={enteredEmail} onChange={e=>setEnteredEmail(e.target.value)}  required/>
                            </div>
                        </div>
                        
                       
                        
                        </div>
                        {/* <div class="form-group mt-3">
                        <button href="#" class="btn_3 button-contactForm" onClick={handleSearch}>Search</button>
                        </div> */}
                    </form>
                    </div>
                    <div class='row my-4'>
                    <div className='col-3'>
                    <button class="btn btn-dark " onClick={handleSearch}>Search</button>
                    </div>
                    </div>
                    <div class='container-fluid'>
                           

                            {
                                ((filteredData!==null) && (found===true))?
                                
                                <div class='my-3'>
                                    <p class='my-2'> Status:-</p>
                                    {filteredData}</div>
                                :
                                ((filteredData===null) && (found===false))?

                                    <div>Order Not Found </div>
                                :
                                <div></div>
                            }
                        </div>
                        
                </div>
                
                </div>
            </div>
    </>
  )
}

export default TrackOrders
