import React from 'react'
import useGetData from '../custom-hooks/useGetData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const AllProducts = () => {

  const { data: productsData } = useGetData("products");
  const navigate= useNavigate();

  const deleteProduct = async id=>{
    await deleteDoc(doc(db, 'products',id));
    alert("Item deleted")
  }
  

  return (
    <div>
  <section class="cart_area section_padding">
    <div class="container">
      <div class="cart_inner">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Title</th>
                <th scope="col">Brand</th>
                <th scope="col">Category</th>
                <th scope="col">Subcategory</th>
                <th scope="col">Mrp</th>
                <th scope="col">Price</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
             {
              productsData.map(item=>(
                <tr key={item.id}>
                <td>
                  <div class="media">
                    <div class="d-flex">
                      <img src={item.img1} alt="" />
                    </div>
                    {/* <div class="media-body">
                      <p>{item.title}</p>
                    </div> */}
                  </div>
                </td>
                <td>
                  {item.title}
                </td>
                <td>
                  {item.brand}
                </td>
                <td>
                 {item.category}
                </td>
                <td>
                  {item.subCategory}
                </td>
                <td>
                  {item.mrp}
                </td>
                <td>
                  {item.price}
                </td>
                <td>
                  <button onClick={()=>{
                    deleteProduct(item.id);
                  }}>Delete</button></td>
                   <td>
                  <button onClick={()=>{
                    navigate(`/dashboard/EditProduct/${item.id}`);
                  }}>Edit</button></td>
              </tr>
              ))
             }
             
             
              
            </tbody>
          </table>
          
        </div>
      </div>
      </div>
  </section>
    </div>
  )
}

export default AllProducts
