import React from 'react';
import { cartActions } from '../../redux/slices/cartSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

export default function Cart() {

    const cartItems =  useSelector(state => state.cart.cartItems);
    const totalAmount = useSelector(state => state.cart.totalAmount);
    const totalQuantity = useSelector(state=>state.cart.totalQuantity);

    return (
        <div>
            <section className="cart_area section_padding">
    <div className="container">
      <div className="cart_inner">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Product</th>
                <th scope="col">Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {
            cartItems.map((item, index)=>(
                <Tr item={item} key={index}/>
            ))
}

              
              {/* <tr className="bottom_button">
                <td>
                  <a className="btn_1" href="#">Update Cart</a>
                </td>
                <td></td>
                <td></td>
                <td>
                  <div className="cupon_text float-right">
                    <a className="btn_1" href="#">Close Coupon</a>
                  </div>
                </td>
              </tr> */}
              <tr>
                <td></td>
                <td></td>
                <td>
                  <h5>Subtotal</h5>
                </td>
                <td>
                  <h5>₹{totalAmount}</h5>
                </td>
              </tr>
              {/* <tr className="shipping_area">
                <td></td>
                <td></td>
                <td>
                  <h5>Shipping</h5>
                </td>
                <td>
                  <div className="shipping_box">
                    <ul className="list">
                      <li>
                        Flat Rate: ₹5.00
                        <input type="radio" aria-label="Radio button for following text input"/>
                      </li>
                      <li>
                        Free Shipping
                        <input type="radio" aria-label="Radio button for following text input"/>
                      </li>
                      <li>
                        Flat Rate: ₹10.00
                        <input type="radio" aria-label="Radio button for following text input"/>
                      </li>
                      <li className="active">
                        Local Delivery: ₹2.00
                        <input type="radio" aria-label="Radio button for following text input"/>
                      </li>
                    </ul>
                    <h6>
                      Calculate Shipping
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </h6>
                    <select className="shipping_select">
                      <option value="1">Bangladesh</option>
                      <option value="2">India</option>
                      <option value="4">Pakistan</option>
                    </select>
                    <select className="shipping_select section_bg">
                      <option value="1">Select a State</option>
                      <option value="2">Select a State</option>
                      <option value="4">Select a State</option>
                    </select>
                    <input className="post_code" type="text" placeholder="Postcode/Zipcode" />
                    <a className="btn_1" href="#">Update Details</a>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
          <div className="checkout_btn_inner float-right">
            <a className="btn_1 mx-3" href="#">Continue Shopping</a>
            <Link className="btn_1 checkout_btn_1" to="/checkout">Proceed to checkout</Link>
          </div>
        </div>
      </div>
      </div>
  </section>
        </div>
    )
}

    const Tr= ({item})=>{

        const dispatch= useDispatch()

        const deleteProduct = ()=>{
            dispatch(cartActions.deleteItem(item.id));
            
        }

        return (<tr>
        <td>
          <div className="media">
            <div className="d-flex">
              <img src={item.imgUrl} alt="" />
            </div>
            <div className="media-body">
              <p>{item.productName}</p>
            </div>
          </div>
        </td>
        <td>
          <h5>₹{item.price}</h5>
        </td>
        <td>
          <h5>{item.quantity}</h5>
        </td>
        <td>
        <span><i onClick={deleteProduct} className="ri-delete-bin-line"></i></span>
        </td>
      </tr>
        );
    
};
