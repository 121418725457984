import { Routes, Route, Navigate } from 'react-router-dom'
import Mobile from '../Pages/Mobiles'
import About from '../Pages/About';
import Cart from '../Pages/Cart';
import Checkout from '../Pages/Checkout';
import Contact from '../Pages/Contact';
import Home from '../Pages/Home';
import Login from '../Pages/Login';
import ProductDetails from '../Pages/ProductDetails';
import Signup from '../Pages/Signup';
import Cancellation from '../Pages/Policies/Cancellation';
import Privacy from '../Pages/Policies/Privacy';
import Shipping from '../Pages/Policies/Shipping';
import Terms from '../Pages/Policies/Terms';
import ProtectedRoute from './ProtectedRoute';
import AllProducts from '../Admin/AllProducts';
import AddProducts from '../Admin/AddProducts';
import Dashboard from '../Admin/Dashboard';
import Users from '../Admin/Users';
import Messages from '../Admin/Messages'
import Sarees from '../Pages/Sarees';
import Mobile_accessories from '../Pages/Mobile-Accessories';
import Laptops from '../Pages/Laptops';
import Crockery from '../Pages/Crockery';
import Orders from '../Admin/Orders'
import OrderDetails from '../Admin/OrderDetails'
import AdminProtectedRoute from './AdminProtectedRoute';
import Borosil from '../Pages/Brands/Borosil';
import Jbl from '../Pages/Brands/Jbl';
import Touchtek from '../Pages/Brands/Touchtek';
import Femora from '../Pages/Brands/Femora';
import UandI from '../Pages/Brands/UandI';
import CheckoutLogin from '../Pages/Login/CheckoutLogin';
import TrackOrders from '../Pages/Contact/TrackOrders';
import EditProduct from '../Admin/EditProduct';
import BillingSystem from '../Pages/Checkout/Billing';
// import CrockeryDetails from '../Pages/Crockery/CrockeryDetails';

export default function Routers() {
    return (
        <Routes>
            <Route path='/' element={ <Navigate to='/home'/> } />
            <Route path='/home' element={<Home/>} />
            <Route path='about' element={<About/>} />
            <Route path='/cart' element={<Cart/>} />

            <Route path='/checkout' element={<ProtectedRoute>
                <Checkout/>
                </ProtectedRoute>}>
            </Route>

                
            
                <Route path="/dashboard/all-products" element={<AdminProtectedRoute> <AllProducts/> </AdminProtectedRoute>}/>
                <Route path="/dashboard/add-products" element={<AdminProtectedRoute> <AddProducts/>  </AdminProtectedRoute>}/>
                <Route path="/dashboard/users" element={<AdminProtectedRoute>  <Users/>  </AdminProtectedRoute>}/>
                <Route path="/dashboard" element={<AdminProtectedRoute> <Orders/> </AdminProtectedRoute>}/>
                <Route path="/dashboard/messages" element={<AdminProtectedRoute> <Messages/> </AdminProtectedRoute>}/>
                <Route path="/dashboard/orders" element={<AdminProtectedRoute> <Orders/> </AdminProtectedRoute>}/>
                <Route path="/dashboard/OrderDetails/:ide" element={<AdminProtectedRoute> <OrderDetails/> </AdminProtectedRoute>}/>
                <Route path="/dashboard/EditProduct/:idk" element={<AdminProtectedRoute><EditProduct/></AdminProtectedRoute>}/>


            <Route path='contact' element={<Contact/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/CheckoutLogin' element={<CheckoutLogin/>}/>
            <Route path='store/:id' element={<ProductDetails/>} />
            <Route path='/signup' element={<Signup/>} />
            <Route path='/borosil' element={<Borosil/>}/>
            <Route path='/femora' element={<Femora/>}/>
            <Route path='/jbl' element={<Jbl/>}/>
            <Route path='/uandi' element={<UandI/>}/>
            <Route path='/Touchtek' element={<Touchtek/>}/>

            <Route path='crockery' element={<Crockery/>} />
            <Route path='/mobiles' element={<Mobile/>} />
            <Route path='/laptops' element={<Laptops/>} />
            <Route path='/sarees' element={<Sarees/>} />
            <Route path='/mobile_accessories' element={<Mobile_accessories/>} />
            <Route path='/trackOrders' element={<TrackOrders/>}></Route>
            {/* <Route path='crockery/:id' element={<Crockery/>} /> */}
            <Route path='Product-Details/:id' element={<ProductDetails/>} />
            <Route path='billing/:idc' element={<BillingSystem/>} />
            <Route path='cancellation-and-refund' element={<Cancellation/>} />
            <Route path='privacy' element={<Privacy/>} />
            <Route path='shipping' element={<Shipping/>} />
            <Route path='terms' element={<Terms/>} />
        </Routes>
    )
}
