import React from 'react'
import useGetData from '../custom-hooks/useGetData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';


const Users = () => {

  const { data: usersData } = useGetData("users");

  const deleteUser = async id=>{
    await deleteDoc(doc(db, 'users',id));
    alert("User deleted")
  }
  

  return (
    <div>
  <section class="cart_area section_padding">
    <div class="container">
      <div class="cart_inner">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
             {
              usersData.map(user=>(
                <tr key={user.uid}>
                {/* <td>
                  <div class="media">
                     <div class="d-flex">
                      <img src={item.imgUrl} alt="" />
                    </div>
                     <div class="media-body">
                      <p>{item.title}</p>
                    </div> 
                  </div> 
                </td> */}
                <td>
                  {user.displayName}
                </td>
                {/* {/* <td>
                 {item.category}
                </td> */}
                <td>
                  {user.email}
                </td> 
                <td>
                  <button
                   onClick={()=>{
                    deleteUser(user.uid);
                  }}
                  >Delete</button></td>
              </tr>
              ))
             }
             
             
              
            </tbody>
          </table>
          
        </div>
      </div>
      </div>
  </section>
    </div>
  )
}

export default Users
