import logo from './../../assets/img/logo.png';
import { useSelector} from "react-redux";
import '../../assets/css/bootstrap.min.css'
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import useAuth from '../../custom-hooks/useAuth';
import { auth } from '../../firebase.config'
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Header() {


    const [Menu, setMenu] = useState(false)
    const { currentUser, loading } = useAuth();
    const totalQuantity = useSelector(state=>state.cart.totalQuantity)
    const navigate = useNavigate()

    const logout =()=>{
        signOut(auth).then(()=>{
            toast("Logged Out");
            navigate("/home")
        }).catch(err=>{
            console.log(err);
            toast("Something Went Wrong");
        })

    }
    const email1= 'admin@smv.com';
    const email2= 'abc@abc.com';
    const menu = ()=>{
        console.log(currentUser.email)
        
        if(currentUser.email === email1 || currentUser.email === email2){
            console.log(email2)
            setMenu(true)
            navigate("/contacts")
        }
        else{
            setMenu(false)
            navigate("/home")
        }
        
    }

    return (
        <div className="main_menu home_menu fixed-top"  style={{backgroundColor: "white"}}>
             <Helmet>
            <link rel="icon" href="img/favicon.png"/>
    
    <link rel="stylesheet" href="./../../assets/css/bootstrap.min.css"/>
   
    <link rel="stylesheet" href="./../../assets/css/animate.css"/>
    
    <link rel="stylesheet" href="./../../assets/css/owl.carousel.min.css"/>
   
    <link rel="stylesheet" href="./../../assets/css/all.css"/>
    
    <link rel="stylesheet" href="./../../assets/css/flaticon.css"/>
    <link rel="stylesheet" href="./../../assets/css/themify-icons.css"/>
   
    <link rel="stylesheet" href="./../../assets/css/magnific-popup.css"/>
   
    <link rel="stylesheet" href="./../../assets/css/slick.css"/>
   
    <link rel="stylesheet" href="./../../assets/css/style.css"/>
    <script src="../../assets/js/jquery-1.12.1.min.js"></script>
   
    <script src="../../assets/js/popper.min.js"></script>
    
    <script src="../../assets/js/bootstrap.min.js"></script>
  
    <script src="../../assets/js/jquery.magnific-popup.js"></script>
   
    <script src="../../assets/js/owl.carousel.min.js"></script>
    <script src="../../assets/js/jquery.nice-select.min.js"></script>
 
    <script src="../../assets/js/slick.min.js"></script>
    <script src="../../assets/js/jquery.counterup.min.js"></script>
    <script src="../../assets/js/waypoints.min.js"></script>
    <script src="../../assets/js/contact.js"></script>
    <script src="../../assets/js/jquery.ajaxchimp.min.js"></script>
    <script src="../../assets/js/jquery.form.js"></script>
    <script src="../../assets/js/jquery.validate.min.js"></script>
    <script src="../../assets/js/mail-script.js"></script>
   
    <script src="../../assets/js/custom.js"></script>
            </Helmet>

            <div className="container "  >
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg navbar-light">

                            <a className="navbar-brand" href="/"> 
                                <img src={logo} alt="Shree Maina Ventures"/> 
                            </a>

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon" ></span>
                            </button>

                            <div className="collapse navbar-collapse main-menu-item" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="about">About</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle"  id="navbarDropdown_1"
                                            role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Categories
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown_1">
                                            <Link className="dropdown-item" to="crockery">Crockery</Link>
                                            <Link className="dropdown-item" to="/mobiles">Mobiles</Link>
                                            <Link className="dropdown-item" to="/laptops">Laptops</Link>
                                            <Link className="dropdown-item" to="/sarees">Sarees</Link>
                                            <Link className="dropdown-item" to="/mobile_accessories">Accessories</Link>
                                            
                                        </div>
                                    </li>
                                    {/* <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle"  id="navbarDropdown_3"
                                            role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Products by Brands
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown_2">
                                            <Link className="dropdown-item" to="borosil">Borosil</Link>
                                            <a className="dropdown-item" href="femora">Femora</a>
                                            <a className="dropdown-item" href="jbl">JBL</a>
                                            <a className="dropdown-item" href="touchtek">Touchtek</a>
                                            <a className="dropdown-item" href="uandi">U&I</a>
                                        </div>
                                    </li> */}
                                    
                                    <li className="nav-item">
                                        <Link className="nav-link" to="contact">Contact</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/trackOrders">Track Orders</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="hearer_icon d-flex align-items-center">
                                { currentUser ? <i onClick={logout} class="ri-logout-box-line ri-2x"></i> :
                            <Link to="/login"><i className="ti-user"></i></Link>
                           
                                }
                                {/* <a onClick={menu}>
                                    <i className="ti-user"></i>
                                </a> */}
                                <span className="badge">{totalQuantity}</span>
                                <Link to="/cart"><i className="ti-shopping-cart"></i></Link>
                                
                            </div>

                        </nav>
                    </div>
                </div>
            </div>

            {/*<div className="search_input" id="search_input_box">
                <div className="container ">
                    <form className="d-flex justify-content-between search-inner">
                        <input type="text" className="form-control" id="search_input" placeholder="Search Here" />
                        <button type="submit" className="btn"></button>
                        <span className="ti-close" id="close_search" title="Close Search"></span>
                    </form>
                </div>
            </div> */}
            <ToastContainer/>

        </div>
    )
}
