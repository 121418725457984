import React from 'react';
import useGetData from '../../custom-hooks/useGetData';
import ProductCard from '../../Components/UI/ProductCard';
import img1 from '../../assets/img/icon/feature_icon_1.svg'
import img2 from '../../assets/img/icon/feature_icon_2.svg'
import img3 from '../../assets/img/icon/feature_icon_3.svg'
import img4 from '../../assets/img/icon/feature_icon_4.svg'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Home() {

    const { data: productsData } = useGetData("products");
    const filteredProducts = productsData.filter(item=>item.trending===true)
    const filteredProducts_crockery= productsData.filter(item=>item.title==='Femora Borosilicate Glass Double Wall Wide Tea Cup, Set of 2 pcs, Transparent, 300 ml')
    const filteredProducts2_crockery= productsData.filter(item=>item.title==='Femora Indian Ceramic Fine Bone China Gold Line Diamond Cut Dinnerware White Tea Cups, Mugs and Saucer-200 ml - Set of 6 (6 Cups, 6 Saucer)')
    const filteredProducts_mobiles= productsData.filter(item=>item.title==='Vivo Y75 (Dancing Waves, 8GB RAM, 128GB ROM)')
    const filteredProducts2_mobiles= productsData.filter(item=>item.title==='Oppo F21s Pro 5G (Dawnlight Gold, 8GB RAM, 128 Storage)')
    const filteredProducts_laptops= productsData.filter(item=>item.title==='LAPCARE Winner Cooling Pad with 4 Fans Laptop Stand, Black')
    const filteredProducts2_laptops= productsData.filter(item=>item.title==='LAPCARE Winner PRO RGB Cooling Pad with 6 Fans Laptop Stand, Black')
    const filteredProducts_sarees= productsData.filter(item=>item.title==='semi Georgette dyeable saree - Patli Pallu')
    const filteredProducts2_sarees= productsData.filter(item=>item.title==='semi Georgette dyeable saree - Half Dye')
    const filteredProducts_accessories= productsData.filter(item=>item.title==='Noise ColorFit Pulse Grand Smart Watch with 1.69"(4.29cm) HD Display, 60 Sports Modes, 150 Watch Faces, Fast Charge, Spo2, Stress, Sleep, Heart Rate Monitoring & IP68 Waterproof (Jet Black)')
    const filteredProducts2_accessories= productsData.filter(item=>item.title==='Noise Newly Launched Buds X Truly Wireless in-Ear Earbuds with ANC(Upto 25dB), 35H Playtime, Quad Mic with ENC, Instacharge(10 min=120 min), 12mm Driver, BT v5.3 (Snow White)')

    return (
        <div>
            
            <section class="single_product_list">
            <Helmet>
            <link rel="icon" href="img/favicon.png"/>
    
    <link rel="stylesheet" href="../../assets/css/bootstrap.min.css"/>
   
    <link rel="stylesheet" href="../../assets/css/animate.css"/>
    
    <link rel="stylesheet" href="../../assets/css/owl.carousel.min.css"/>
   
    <link rel="stylesheet" href="../../assets/css/all.css"/>
    
    <link rel="stylesheet" href="../../assets/css/flaticon.css"/>
    <link rel="stylesheet" href="../../assets/css/themify-icons.css"/>
   
    <link rel="stylesheet" href="../../assets/css/magnific-popup.css"/>
   
    <link rel="stylesheet" href="../../assets/css/slick.css"/>
   
    <link rel="stylesheet" href="../../assets/css/style.css"/>
    <script src="../../assets/js/jquery-1.12.1.min.js"></script>
   
    <script src="../../assets/js/popper.min.js"></script>
    
    <script src="../../assets/js/bootstrap.min.js"></script>
  
    <script src="../../assets/js/jquery.magnific-popup.js"></script>
   
    <script src="../../assets/js/owl.carousel.min.js"></script>
    <script src="../../assets/js/jquery.nice-select.min.js"></script>
 
    <script src="../../assets/js/slick.min.js"></script>
    <script src="../../assets/js/jquery.counterup.min.js"></script>
    <script src="../../assets/js/waypoints.min.js"></script>
    <script src="../../assets/js/contact.js"></script>
    <script src="../../assets/js/jquery.ajaxchimp.min.js"></script>
    <script src="../../assets/js/jquery.form.js"></script>
    <script src="../../assets/js/jquery.validate.min.js"></script>
    <script src="../../assets/js/mail-script.js"></script>
   
    <script src="../../assets/js/custom.js"></script>
            </Helmet>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5">
                   
                        <div class="banner_text_iner my-3">
                            <h1>Best quality
                                Crockery</h1>
                            <p>A Reliable everyday essential that adds a touch of sophistication to any meal.</p>
                            <Link to="/crockery" class="btn_1">shop now</Link>
                        </div>
                   
                    
                </div>
                <div className="col ml-5">
                {
                                filteredProducts_crockery.map((item, index)=>(
                                    <ProductCard item={item} key={index}/>
                                ))
                            }
                </div>
                <div className="col ml-5">
                {
                                filteredProducts2_crockery.map((item, index)=>(
                                    <ProductCard item={item} key={index}/>
                                ))
                            }
                </div>
                {/* <div className="col ml-5">
                <ProductCard item={productsData}/>
                </div> */}
            </div>
            
       
        </div>
       
    </section>



    <section class="single_product_list">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="single_product_iner">
                        <div class="row align-items-center justify-content-between">
                            
                            <div className="col mr-5">
                            {
                                filteredProducts_mobiles.map((item, index)=>(
                                    <ProductCard item={item} key={index}/>
                                ))
                            }
                </div>
                <div className="col mr-5">
                {
                                filteredProducts2_mobiles.map((item, index)=>(
                                    <ProductCard item={item} key={index}/>
                                ))
                            }
                </div>
                {/* <div className="col mr-5">
                <ProductCard item={productsData}/>
                </div> */}
                           
                            <div class="col-lg-5 col-sm-6">
                                <div class="single_product_content">
                                    {/*<h5>Started from $10</h5>*/}
                                    <h2> <a href="single-product.html">Best quality
                                Mobiles</a> </h2>
                                    <Link to="/mobiles" class="btn_3">Explore Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single_product_iner">
                        <div class="row align-items-center justify-content-between">
                        <div className="col ml-5">
                        {
                                filteredProducts_laptops.map((item, index)=>(
                                    <ProductCard item={item} key={index}/>
                                ))
                            }
                </div>
                <div className="col ml-5">
                {
                                filteredProducts2_laptops.map((item, index)=>(
                                    <ProductCard item={item} key={index}/>
                                ))
                            }
                </div>
                {/* <div className="col ml-5">
                <ProductCard item={productsData}/>
                </div> */}
                            <div class="col-lg-5 col-sm-6">
                                <div class="single_product_content">
                                    {/*<h5>Started from $10</h5>*/}
                                    <h2> <a href="single-product.html">Best quality
                                Laptops</a> </h2>
                                    <Link to="/laptops" class="btn_3">Explore Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single_product_iner">
                        <div class="row align-items-center justify-content-between">
                        <div className="col mr-5">
                        {filteredProducts_sarees.map((item, index)=>(
                                
                                
                                    <ProductCard item={item} key={index}/>
                                         
                   
                
                                ))
                            }
                </div>
                <div className="col mr-5">
                {
                                filteredProducts2_sarees.map((item, index)=>(
                                    <ProductCard item={item} key={index}/>
                                ))
                            }
                </div>
                
                {/* <div className="col ml-5">
                <ProductCard item={productsData}/>
                </div> */}
                
                            <div class="col-lg-5 col-sm-6">
                                <div class="single_product_content">
                                    {/*<h5>Started from $10</h5>*/}
                                    <h2> <a href="single-product.html">Best Quality Sarees</a> </h2>
                                    <Link to="/sarees" class="btn_3">Explore Now</Link>
                                </div>
                            </div>
                            <div class="single_product_iner">
                        <div class="row align-items-center justify-content-between">
                        <div className="col ml-5">
                        {
                               filteredProducts_accessories.map((item, index)=>(
                                    <ProductCard item={item} key={index}/>
                                ))
                            }
                </div>
                <div className="col ml-5">
                {
                                filteredProducts2_accessories.map((item, index)=>(
                                    <ProductCard item={item} key={index}/>
                                ))
                            }
                </div>
                {/* <div className="col ml-5">
                <ProductCard item={productsData}/>
                </div> */}
                            <div class="col-lg-5 col-sm-6">
                                <div class="single_product_content">
                                    {/*<h5>Started from $10</h5>*/}
                                    <h2> <a href="single-product.html">Best quality
                                Accesories</a> </h2>
                                    <Link to="/mobile_accessories" class="btn_3">Explore Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* {
                         //////////////   TRENDING ITEMS   //////////////
                             */}

    <section class="trending_items">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section_tittle text-center">
                        <h2>Trending Items</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                
                         {filteredProducts?.map((item, index)=>(
                                 <div class="col-lg-4 col-sm-6">
                                 <div class="single_product_item">
                                    <ProductCard item={item} key={index}/>
                                         
                    </div>
                </div>
                                ))
                            }
                   
                   
                
            </div>
        </div>
    </section>


{/*   
    <section class="client_review">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="client_review_slider owl-carousel">
                        <div class="single_client_review">
                            <div class="client_img">
                                <img src="img/client.png" alt="#"/>
                            </div>
                            <p>"Working in conjunction with humanitarian aid agencies, we have supported programmes to help alleviate human suffering.</p>
                            <h5>- Micky Mouse</h5>
                        </div>
                        <div class="single_client_review">
                            <div class="client_img">
                                <img src="img/client_1.png" alt="#"/>
                            </div>
                            <p>"Working in conjunction with humanitarian aid agencies, we have supported programmes to help alleviate human suffering.</p>
                            <h5>- Micky Mouse</h5>
                        </div>
                        <div class="single_client_review">
                            <div class="client_img">
                                <img src="img/client_2.png" alt="#"/>
                            </div>
                            <p>"Working in conjunction with humanitarian aid agencies, we have supported programmes to help alleviate human suffering.</p>
                            <h5>- Micky Mouse</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
     */}


   
    <section class="feature_part section_padding">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-lg-6">
                    <div class="feature_part_tittle">
                        <h3>Your Online store for Laptop, Mobiles, Crockery and Saree Requirements.</h3>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="feature_part_content">
                        <p>Check out now at Best Prices a huge selection of latest and best mobile phones, laptops and crockery items from the world's leading brands like Borosil, Femora, HP, Dell, JBL etc.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="single_feature_part">
                        <img src={img1} alt="#"/>
                        <h4>Credit Card Support</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="single_feature_part">
                        <img src={img2} alt="#"/>
                        <h4>Online Order</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="single_feature_part">
                        <img src={img3} alt="#"/>
                        <h4>Free Delivery</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="single_feature_part">
                        <img src={img4} alt="#"/>
                        <h4>Product with Gift</h4>
                    </div>
                </div>
            </div>
        </div>
    </section>
   

    
   
        </div>
    )
}
