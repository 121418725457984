import React from 'react'
import useGetData from '../custom-hooks/useGetData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Orders = () => {

  const navigate= useNavigate();
  const { data: productsData } = useGetData("orders");

  const deleteProduct = async id=>{
    await deleteDoc(doc(db, 'orders',id));
    alert("Order deleted")
  }
  

  return (
    <div>
  <section class="cart_area section_padding">
    <div class="container">
      <div class="cart_inner">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                {/* <th scope="col">Image</th> */}
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Address</th>
                <th scope="col">Amount</th>
                <th scope="col">Mobile</th>
                <th scope="col">Time</th>
                <th scope="col">Status</th>
                {/* <th scope="col">Mrp</th>
                <th scope="col">Price</th>
                <th scope="col">Delete</th> */}
              </tr>
            </thead>
            <tbody>
             {
              productsData.map(item=>(
                <tr key={item.id}>
                {/* <td>
                  <div class="media">
                    <div class="d-flex">
                      <img src={item.imgUrl} alt="" />
                    </div> */}
                    {/* <div class="media-body">
                      <p>{item.title}</p>
                    </div> */}
                  {/* </div>
                </td> */}
                <td>
                  {item.FirstName}
                </td>
                <td>
                  {item.Email}
                </td>
                <td>
                 {item.Address}
                </td>
                <td>
                  {item.Sum}
                </td>
                <td>
                  {item.Phone}
                </td>
                <td>
                  {/* {item.createdAt} */}
                </td>
                <td>
                  {item.Status}
                </td>
                {/* <td>
                  {item.mrp}
                </td>
                <td>
                  {item.price}
                </td> */}
                <td>
                  <button onClick={()=>{
                    deleteProduct(item.id);
                  }}>Delete</button></td>
                  <td>
                  <button onClick={()=>{
                    navigate(`/dashboard/OrderDetails/${item.id}`);
                  }}>Details</button></td>
              </tr>
              ))
             }
             
             
              
            </tbody>
          </table>
          
        </div>
      </div>
      </div>
  </section>
    </div>
  )
}

export default Orders
