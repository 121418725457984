import React from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom'
// import products from '../../assets/data/products';
import {useDispatch} from 'react-redux'
import {cartActions} from '../../redux/slices/cartSlice';
import useGetData from '../../custom-hooks/useGetData';
import {db} from '../../firebase.config'
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
// import img1 from "../../assets/images/arm-chair-01.jpg"
// import img2 from "../../assets/images/arm-chair-02.jpg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ProductDetails = () => {

  const { data: products } = useGetData("products");

const { id } = useParams();

const [product, setProduct] = useState({})
const [enterQuantity, setEnterQuantity] = useState(1)
// const [urls, setUrls] = useState([])
const [isLoading, setIsLoading] = useState(true);

const increase=()=>{
  const initial = enterQuantity;
  setEnterQuantity(initial+1);
}

const decrease=()=>{
  const initial =enterQuantity;
  setEnterQuantity(initial-1);
}

const docRef= doc(db,'products', id)
useEffect(() => {
  const getProduct = async()=>{
    const docSnap = await getDoc(docRef)
  
    if(docSnap.exists()){
      setProduct(docSnap.data())
  
    }
    else{
      console.log('no product')
    }
  }
  
  getProduct()
  
  }, [])
  
  // const product = productsData.find((item)=> item.id === id);
  const{ urls,img1 ,img2, img3 , title, price, description, mrp, brand, category, warranty} = product;
const dispatch = useDispatch();
const addToCart =()=>{
    console.log(enterQuantity)
  dispatch(cartActions.addItem({
  id,
  imgUrl: img1,
  productName: title,
  quantity: enterQuantity,
  price,
  description,
  
}));
toast('Added to Cart');
}

if (!img1) {
  return <p>Loading...</p>;
}
else{
  return (
    <div>
      <Helmet>
      <link rel="icon" href="img/favicon.png"/>
    <link rel="stylesheet" href="../../assets/css/bootstrap.min.css"/>
    <link rel="stylesheet" href="../../assets/css/animate.css"/>
    <link rel="stylesheet" href="../../assets/css/owl.carousel.min.css"/>
    <link rel="stylesheet" href="../../assets/css/all.css"/>
    <link rel="stylesheet" href="../../assets/css/flaticon.css"/>
    <link rel="stylesheet" href="../../assets/css/themify-icons.css"/>
    <link rel="stylesheet" href="../../assets/css/magnific-popup.css"/>
    <link rel="stylesheet" href="../../assets/css/slick.css"/>
    <link rel="stylesheet" href="../../assets/css/style.css"/>

    <script src="../../assets/js/jquery-1.12.1.min.js"></script>
    <script src="../../assets/js/popper.min.js"></script>
    <script src="../../assets/js/bootstrap.min.js"></script>
    <script src="../../assets/js/jquery.magnific-popup.js"></script>
    <script src="../../assets/js/swiper.min.js"></script>
    <script src="../../assets/js/mixitup.min.js"></script>
    <script src="../../assets/js/owl.carousel.min.js"></script>
    <script src="../../assets/js/jquery.nice-select.min.js"></script>
    <script src="../../assets/js/slick.min.js"></script>
    <script src="../../assets/js/jquery.counterup.min.js"></script>
    <script src="../../assets/js/waypoints.min.js"></script>
    <script src="../../assets/js/contact.js"></script>
    <script src="../../assets/js/jquery.ajaxchimp.min.js"></script>
    <script src="../../assets/js/jquery.form.js"></script>
    <script src="../../assets/js/jquery.validate.min.js"></script>
    <script src="../../assets/js/mail-script.js"></script>
    <script src="../../assets/js/custom.js"></script>
      </Helmet>
       {/* <!-- breadcrumb part start--> */}
    <section className="breadcrumb_part single_product_breadcrumb">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="breadcrumb_iner">
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- breadcrumb part end--> */}

  
  <div className="product_image_area">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          {/* <div className="product_img_slide owl-carousel">
            <div className="single_product_img">
              <img src={urls[0]} alt="#" className="img-fluid"/>
            </div>
            <div className="single_product_img">
              <img src={urls[1]} alt="#" className="img-fluid"/>
            </div>
            <div className="single_product_img">
              <img src={urls[2]} alt="#" className="img-fluid"/>
            </div>
          </div> */}

<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img1} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={img2} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={img3} class="d-block w-100" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

        </div>
        <div className="col-lg-8">
          <div className="single_product_text text-center">
            <h3>{title}</h3>
            <p>
               {description}
            </p>
            <div class="container row mt-5" style={{color: 'inherit'}}>
              <div class="col">
                Brand:- {brand}
              </div>
              <div className="col">
                Mrp:- ₹{mrp}
              </div>
              <div className="col">
                Warranty:- {warranty} months
              </div>
              <div className="col">
                Category:- {category}
              </div>
            </div>
            <div className="card_area">
                <div className="product_count_area">
                    <p>Quantity</p>
                    <div className="product_count d-inline-block">
                        <span className="product_count_item inumber-decrement" onClick={decrease}> <i className="ti-minus"></i></span>
                        <input className="product_count_item input-number" type="text" value={enterQuantity} onChange={e=>setEnterQuantity(e.target.value)} min="0" max="10"/>
                        <span className="product_count_item number-increment" onClick={increase}> <i className="ti-plus"></i></span>
                    </div>
                    <p>₹ {price}</p>
                </div>
              <div className="add_to_cart">
                  <a onClick={addToCart} className="btn_3">add to cart</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ToastContainer/>
</div>
  )}
}

export default ProductDetails
