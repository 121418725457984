import Layout from "./Components/Layout"
import Scripts from "./Components/Scripts"
import "./App.css"
import { Helmet } from "react-helmet"

export default function App() {
    return (
        <>
            <Helmet>
                <script type="text/babel" src="assets/js/popper.min.js"></script>
                <script type="text/babel" src="assets/js/bootstrap.min.js"></script>
                <script type="text/babel" src="assets/js/jquery.magnific-popup.js"></script>
                <script type="text/babel" src="assets/js/swiper.min.js"></script>
                <script type="text/babel" src="assets/js/mixitup.min.js"></script>
                <script type="text/babel" src="assets/js/owl.carousel.min.js"></script>
                <script type="text/babel" src="assets/js/jquery.nice-select.min.js"></script>
                <script type="text/babel" src="assets/js/slick.min.js"></script>
                <script type="text/babel" src="assets/js/jquery.counterup.min.js"></script>
                <script type="text/babel" src="assets/js/waypoints.min.js"></script>
                <script type="text/babel" src="assets/js/contact.js"></script>
                <script type="text/babel" src="assets/js/jquery.ajaxchimp.min.js"></script>
                <script type="text/babel" src="assets/js/jquery.form.js"></script>
                <script type="text/babel" src="assets/js/jquery.validate.min.js"></script>
                <script type="text/babel" src="assets/js/mail-script.js"></script>
            </Helmet>
            <Layout />
        </> 
    )
}
