import React from 'react'
import logo from '../assets/img/logo.png';
import { useSelector} from "react-redux";

import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import useAuth from '../custom-hooks/useAuth';
import { auth } from '../firebase.config'
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';


const AdminNav = () => {

  const currentUser = useAuth()
    const totalQuantity = useSelector(state=>state.cart.totalQuantity)
    const navigate = useNavigate()

    const logout =()=>{
        signOut(auth).then(()=>{
            alert("Logged Out")
            navigate("/home")
        }).catch(err=>{
            console.log(err);
            alert("something went wrong")
        })

    }

  return (
    <>
      <div className="main_menu home_menu">

<div className="container">
    <div className="row align-items-center justify-content-center">
        <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg navbar-light">

                <a className="navbar-brand" href="/"> 
                    <img src={logo} alt="Shree Maina Ventures"/> 
                </a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation"
                >
                    <span className="menu_icon"><i className="fas fa-bars"></i></span>
                </button>

                <div className="collapse navbar-collapse main-menu-item" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard">Orders</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/add-products">Add Products</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/all-products">All Products</Link>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="blog.html" id="navbarDropdown_1"
                                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Categories
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown_1">
                                <Link className="dropdown-item" to="crockery">Crockery</Link>
                                <a className="dropdown-item" href="mobiles">Mobiles</a>
                                <a className="dropdown-item" href="laptops">Laptops</a>
                                <a className="dropdown-item" href="sarees">Sarees</a>
                                <a className="dropdown-item" href="mobile-accessories">Mobile Accessories</a>
                                
                            </div>
                        </li> */}
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="blog.html" id="navbarDropdown_3"
                                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Products by Brands
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown_2">
                                <Link className="dropdown-item" to="borosil">Borosil</Link>
                                <a className="dropdown-item" href="femora">Femora</a>
                                <a className="dropdown-item" href="jbl">JBL</a>
                                <a className="dropdown-item" href="touchtek">Touchtek</a>
                                <a className="dropdown-item" href="uandi">U&I</a>
                            </div>
                        </li> */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/users">Users</Link>
                        </li>
                       
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/messages">Contacts</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/orders">Orders</Link>
                        </li> */}
                    </ul>
                </div>

                <div className="hearer_icon d-flex align-items-center">
                    { currentUser ? <i onClick={logout}  class="ri-logout-box-line ri-2x"></i> :
                <Link to="/login"><i class="ri-login-box-line ri-2x"></i></Link>
               
                    }
                    <Link to='/dashboard'>
                        <i className="ti-user"></i>
                    </Link>
                    <span className="badge">{totalQuantity}</span>
                    <Link to="/cart"><i className="ti-shopping-cart"></i></Link>
                    
                </div>

            </nav>
        </div>
    </div>
</div>

{/*<div className="search_input" id="search_input_box">
    <div className="container ">
        <form className="d-flex justify-content-between search-inner">
            <input type="text" className="form-control" id="search_input" placeholder="Search Here" />
            <button type="submit" className="btn"></button>
            <span className="ti-close" id="close_search" title="Close Search"></span>
        </form>
    </div>
</div> */}

</div>
    </>
  )
}

export default AdminNav
