import React, { useState } from 'react';

import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
// import { ref, uploadBytesResumable, getDownloadUrl } from 'firebase/storage';
// import { storage } from "../../firebase.config"
import { auth } from '../../firebase.config';
import { Form, FormGroup } from 'reactstrap';
import { setDoc, doc } from "firebase/firestore";
import { db } from '../../firebase.config'
import { useNavigate } from 'react-router-dom';

export default function Signup() {

    const [ username, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loading, setLoading ] = useState(false);

   const navigate = useNavigate()
    
    

    const signup = async(e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
           

            await setDoc(doc(db,'users',user.uid),{
                uid: user.uid,
                displayName: username,
                email,
            });

            setLoading(false);
            alert("Account Created");
            navigate('/login');

        } catch (error) {
            setLoading(false);
            console.log(error);
            alert("something went wrong")
        }
    }

    return (
        <>
            <div className="login_part section_padding ">
                <div className="container">
                    <div className="row align-items-center">
                    {
                        loading? <div className="text-center">
                        <h3>loading....</h3> 
                    </div> : <div></div> 
                    }
                        <div className="col-lg-6 col-md-6">
                            <div className="login_part_form">
                                <div className="login_part_form_iner">
                                    <h3>Welcome Back ! <br/> Please Sign Up now</h3>

                                    <Form className="row contact_form" onSubmit={signup}>
                                        <FormGroup className="col-md-12 form-group p_star">
                                            <input 
                                                type="text" className="form-control"
                                                placeholder='Name'
                                                value={username}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </FormGroup>
                                        
                                        <FormGroup className="col-md-12 form-group p_star">
                                            <input type="email" className="form-control" id="Email" name="Email" value={email}
                                              onChange={(e)=> setEmail(e.target.value)}  placeholder="Email" />
                                        </FormGroup>

                                        <FormGroup className="col-md-12 form-group p_star">
                                            <input type="password" className="form-control" id="password" name="password" value={password}
                                               onChange={(e)=> setPassword(e.target.value)} placeholder="Password" />
                                        </FormGroup>
                                        <FormGroup className="col-md-12 form-group">
                                            <div className="creat_account d-flex align-items-center">
                                                <input type="checkbox" id="f-option" name="selector" />
                                                <label for="f-option">Remember me</label>
                                            </div>
                                            <button type="submit" value="submit" className="btn_3">
                                                Sign In
                                            </button>
                                            <a className="lost_pass" href="#">forget password?</a>
                                        </FormGroup>
                                    </Form>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="login_part_text text-center">
                                <div className="login_part_text_iner">
                                    <h2>Already have an Account?</h2>
                                    <p>Then why waste time! Login Now and enjoy the Best Experience with us.</p>
                                    <a href="login" className="btn_3">Login into Account</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
