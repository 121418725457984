import React from 'react'
import { useEffect, useState } from 'react'
import useAuth from '../custom-hooks/useAuth'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { auth } from '../firebase.config'



const AdminProtectedRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    // Return a loading indicator while the authentication check is in progress
    return <div className="text-center"><h3>Loading...</h3></div>;
  } else if (currentUser && (currentUser.email === 'abc@abc.com' || currentUser.email === 'admin@smv.com' || currentUser.email === 'dev@smv.com.com')) {
    // Render the children if the user is authenticated as an admin
    return <>{children}</>;
  } else {
    // Redirect to the home page if the user is not authenticated as an admin
    return <Navigate to="/login" />;
  }
};

export default AdminProtectedRoute;