import logo from './../../assets/img/logo.png';

export default function Footer() {
    return (
        <>
            <div className="footer_part">

                <div className="footer_iner section_bg">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-8">
                                <div className="footer_menu">
                                    <div className="footer_logo">
                                        <a href="/">
                                            <img src={logo} alt="Shree Maina Ventures" />
                                        </a>
                                    </div>
                                    <div className="footer_menu_item">
                                        <a href="/">Home</a>
                                        <a href="about">About</a>
                                        <a href="terms">T&Cs</a>
                                        <a href="privacy">Privacy</a>
                                        <a href="contact">Contact</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="social_icon">
                                    {/* <a href="/"><i className="fab fa-phone"></i></a>
                                    <a href="/"><i className="fab fa-mail"></i></a>
                                    <a href="/"><i className="fab fa-map-marker"></i></a> */}
                                    <a href="/"><i className="fab fa-facebook-f"></i></a>
                                    <a href="/"><i className="fab fa-youtube"></i></a>
                                    <a href="/"><i className="fab fa-whatsapp"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="copyright_part">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div className="copyright_text">
                                    <p>
                                        Copyright &copy; 2023 | &nbsp; <i className="ti-desktop" aria-hidden="true"></i> &nbsp; by <a href="https://wecodeforyou.xyz" target="_blank">We Code For You</a>
                                    </p>
                                    <div className="copyright_link">
                                        <a href="cancellation-and-refund">Cancellation & Refund</a>
                                        <a href="shipping">Shipping</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
