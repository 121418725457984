import Header from "../Header"
import Footer from "../Footer"
import Routers from "../../Routers"
import AdminNav from "../../Admin/AdminNav"
import { useLocation } from "react-router-dom"

export default function Layout() {

    const location = useLocation()
    return <>
        {
            location.pathname.startsWith('/dashboard') ? <AdminNav/> : <Header/> 
        }

        
        <div>
            <Routers />
        </div>
        <Footer />
    </>
}
