import React  from 'react'
import {useState, useEffect } from 'react'
import useGetData from '../custom-hooks/useGetData';
import { db } from '../firebase.config';
import { doc, deleteDoc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'


const OrderDetails = () => {

    const { ide } = useParams();

    const [order, setOrder] = useState({});
   

    const docRef= doc(db,'orders', ide)
    const{ Address , City, Company, Email, FirstName, LastName, Notes, Phone, Pincode, Status, Sum, CreatedAt, itemDetails} = order;
    const [changedStatus, setChangedStatus] = useState(Status)

    const handleChange= async ()=>{
       console.log(changedStatus)
        try {
            await updateDoc(docRef, { Status: changedStatus }); // Update the attribute with the new value
            alert('Status updated successfully!');
          } catch (error) {
            console.error('Error updating document attribute: ', error);
          }
          navigate('/dashboard/orders')
        
    }


    useEffect(() => {
    const getOrder = async()=>{
    const docSnap = await getDoc(docRef)

    if(docSnap.exists()){
        setOrder(docSnap.data())

    }
    else{
        console.log('no order')
    }
    }

    getOrder()

    }, [])

    // const product = productsData.find((item)=> item.id === id);
   

    const navigate= useNavigate();
    const { data: productsData } = useGetData("orders");

  return (
    <div>
        <div className="container-fluid ml-2 text-center my-5"><h3>Order Details</h3> </div>
        <section class="cart_area section_padding">
         <div class="container ">
      <div class="cart_inner">
        <div class="table-responsive"></div>
        <table class="table">
  <tbody>
    <tr>
      <th>First Name</th>
      <td>{FirstName}</td>
      
    </tr>
    <tr>
      <th>Last Name</th>
      <td>{LastName}</td>
      
    </tr>
    <tr>
      <th>Address</th>
    <td>{Address}</td>
   
    </tr>
    <tr>
      <th>City</th>
      <td>{City}</td>
   
    </tr>
    <tr>
      <th>Email</th>
      <td>{Email}</td>
   
    </tr>
    <tr>
      <th>Company</th>
      <td>{Company}</td>
   
    </tr>
    <tr>
      <th>Phone</th>
      <td>{Phone}</td>
    
    </tr>
    <tr>
      <th>Pincode</th>
      <td>{Pincode}</td>
   
    </tr>
    <tr>
      <th>Status</th>
      <td>{Status}</td>
   
    </tr>
    <tr>
      <th>Amount</th>
      <td>{Sum}</td>
   
    </tr>
    {/* <tr>
      <th>Products</th>
      <td>{itemDetails.name}</td>
      <td>{itemDetails.quantity}</td>
   
    </tr> */}

  </tbody>
  <select class="form-select" aria-label="Default select example"  value={changedStatus} onChange={e=>setChangedStatus(e.target.value)}
                                        >
                                          
                                          <option selected>Change Order Status</option>
                                          <option value="Captured">Captured</option>
                                          <option value="Dispatched">Dispatched</option>
                                          <option value="Cancelled">Cancelled</option>
                                          <option value="Completed">Completed</option>
                                          
                                        </select>
</table>
</div>
</div>
<button class="btn btn-dark mt-5 mx-auto d-block" onClick={handleChange}> Confirm Change  </button> 
                                             
</section>
    </div>
  )
}

export default OrderDetails
