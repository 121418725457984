import React from 'react';
import ProductCard from '../../Components/UI/ProductCard';
// import productImg from '../../assets/images/arm-chair-01.jpg';
// import products from '../../assets/data/products'
import useGetData from '../../custom-hooks/useGetData';
import { db } from '../../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';

export default function Crockery() {

    const { data: productsData } = useGetData("products");
   const filteredProducts= productsData.filter(item=>item.category==='Crockery')

    return (
        <>
         {/* <!-- breadcrumb part start--> */}
    <section className="breadcrumb_part">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="breadcrumb_iner">
                        <h2>Crockery</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- breadcrumb part end--> */}
    
    {/* <!-- product list part start--> */}
    <section class="product_list section_padding ">
        <div class="container" >
            <div class="row ">
               
                
                    
                        <div class="row row-cols-2 row-cols-sm-3 ">
                            {
                                filteredProducts.map((item, index)=>(
                                    <ProductCard item={item} key={index}/>
                                ))
                            }
                            

                        </div>
                        {/* </div> */}
                        <div class="load_more_btn text-center" >
                            <a href="#" class="btn_3">Load More</a>
                        </div>
                    
                
            </div>
        </div>
        {/* </div> */}
    </section>
    
   

  
   
        </>
    )
}
