import React from 'react'
import useAuth from '../custom-hooks/useAuth'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({children}) => {

  const { currentUser, loading } = useAuth();

  if (loading) {
    // Return a loading indicator while the authentication check is in progress
    return <div className="text-center"><h3>Loading...</h3></div>;
  } else if (currentUser) {
    // Render the children if the user is authenticated as an admin
    return <>{children}</>;
  } else {
    // Redirect to the home page if the user is not authenticated as an admin
    return <Navigate to="/CheckoutLogin" />;
  }
};

export default ProtectedRoute
