 import React from 'react'
//  import productImg from '../../assets/images/arm-chair-01.jpg';
//  import {motion} from "framer-motion";


import { Link } from 'react-router-dom';


 const ProductCard = ({item}) => {

 

   return (
   
    <div className="single_product_item">
        <img src={item.img1} alt="#" className="img-fluid" style={{width: 'auto', height: '150px'}}/>
        <h3> <Link to={`/Product-Details/${item.id}`}>{item.title.slice(0,75)}...</Link> </h3>
        {/* <p>{item.description}</p> */}
        <p>₹{item.price}</p>
        
    </div>
   )
 }

 
 export default ProductCard;