import React from 'react'
import {useState, useEffect } from 'react'
import useGetData from '../custom-hooks/useGetData';
import { db } from '../firebase.config';
import { doc, deleteDoc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { ref, uploadBytesResumable, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage} from '../firebase.config'



const EditProduct = () => {

    const { idk } = useParams();

    const [product, setProduct] = useState({});
    const [enterTitle, setEnterTitle] = useState('')
    const [enterDescription, setEnterDescription] = useState('')
    const [enterBrand, setEnterBrand] = useState('')
    const [enterCategory, setEnterCategory] = useState('')
    const [enterPrice, setEnterPrice] = useState('')
    const [enterMrp, setEnterMrp] = useState('')
    const [enterSubCategory, setEnterSubCategory] = useState('')
    const [enterWrranty, setEnterWarranty] = useState('')
    const [enterProductImg, setEnterProductImg] = useState(null)
    var imageUpload = []
    const [loading, setLoading] = useState(false)
    const [enterTrending, setEnterTrending] = useState(false)
    const [enterInventory, setEnterInventory] = useState('')

    const toggle = ()=>{
        if(enterTrending==false){
            setEnterTrending(true)
        }
        else{
            setEnterTrending(false)
        }
    }

   

    const docRef= doc(db,'products', idk)
    const{ brand , category, description, mrp, price, subCategory, title, trending, warranty} = product;
    
    let date = new Date().toJSON();

    const [imageUrls, setImageUrls] = useState([]);

    const handleImageUpload=(event)=>{
        imageUpload=event.target.files;
       
    }

    const handleChange= async ()=>{
        setLoading(true);
        const files = imageUpload;
        
        const urls = [];



        try {

            for (const file of files) {
                const storageRef = ref(storage, `images/${file.name}`);
                await uploadBytes(storageRef, file);
                const downloadUrl = await getDownloadURL(storageRef);
                urls.push(downloadUrl);
              }

            await updateDoc(docRef, {title: enterTitle,
                brand: enterBrand,
                description: enterDescription,
                category: enterCategory,
                subCategory: enterSubCategory,
                mrp: enterMrp,
                price: enterPrice,
                warranty: enterWrranty,
                trending: enterTrending,
                inventory: enterInventory,

                updatedAt: date,
                urls
            }); // Update the attribute with the new value
            alert('Product updated successfully!');
          } catch (error) {
            console.error('Error updating document attribute: ', error);
          }
          navigate('/dashboard/all-products')
        
    }


    useEffect(() => {
    const getProduct = async()=>{
    const docSnap = await getDoc(docRef)

    if(docSnap.exists()){
        setProduct(docSnap.data())

    }
    else{
        console.log('no Product')
    }
    }

    getProduct()

    }, [])

    // const product = productsData.find((item)=> item.id === id);
   

    const navigate= useNavigate();
    const { data: productsData } = useGetData("products");


  return (
    <>
    {loading ?  <div className="container-fluid ml-2 text-center"><h3>Loading...</h3> </div> :
    <section>
        <div class="container-fluid ml-1 ">      
            <div class="row ">
                <div class="col-lg-10 col-md-8 ml-4 mr-3">
                    <div class="row align-items-center pt-md-5 mt-md-5 mb-5">
                        <div class="col-12">
                            <div class="card">
                              { loading ? <div class="card-title text-center mt-3">
                                    <h3>Loading....</h3>
                                </div> :
                                <div class="card-title text-center mt-3">
                                    <h3>Edit Product Details</h3>
                                </div>
                                }
                                <div class="card-body">
                                    <form >
                                        <div class="form-group">
                                            <label for="productname">Product Name:</label>
                                            <input type="text" class="form-control" id="productname"
                                                placeholder="Enter Product Name" value={enterTitle} onChange={e=>setEnterTitle(e.target.value)}/>
                                            <div class="invalid-feedback">Product Name Can't Be Empty</div>
                                        </div>
                                        {/* <div class="form-group my-3">
                                            <label for="productid">Product Id:</label>
                                            <input type="text" class="form-control" id="productid"
                                                placeholder="Enter Product Id"
                                                />
                                            <div class="invalid-feedback">Product ID Can't Be Empty</div>
                                        </div> */}
                                        <div class="form-group ">
                                            <label for="productname">Brand</label>
                                            <input type="text" class="form-control" id="shortDescription"
                                                placeholder="Enter Brand Name"
                                                value={enterBrand} onChange={e=>setEnterBrand(e.target.value)}/>
                                            <div class="invalid-feedback">Product Brand</div>
                                        </div>
                                        
                                        <div class="form-group ">
                                            <label for="productname">Description:</label>
                                            <input type="text" class="form-control" id="description"
                                                placeholder="Enter Description"
                                                value={enterDescription} onChange={e=>setEnterDescription(e.target.value)}/>
                                            <div class="invalid-feedback">Description Can't Be Empty</div>
                                        </div>
                                        <div class="form-group ">
                                            <label for="productname">Warranty(in months):</label>
                                            <input type="text" class="form-control" id="description"
                                                placeholder="Enter Warranty"
                                                value={enterWrranty} onChange={e=>setEnterWarranty(e.target.value)}/>
                                            <div class="invalid-feedback">Description Can't Be Empty</div>
                                        </div>
                                        <div class="form-group ">
                                            <label for="productprice">Mrp</label>
                                            <input type="text" class="form-control" id="productprice"
                                                placeholder="Enter Mrp"
                                                value={enterMrp} onChange={e=>setEnterMrp(e.target.value)}/>
                                            <div class="invalid-feedback">Product mrp:</div>

                                        </div>
                                        <div class="form-group">
                                            <label for="productprice">Product Price:</label>
                                            <input type="text" class="form-control" id="productprice"
                                                placeholder="Enter Product Price"
                                                value={enterPrice} onChange={e=>setEnterPrice(e.target.value)}/>
                                            <div class="invalid-feedback">Product Price Can't Be Empty</div>

                                        </div>
                                        <select class="form-select my-4" aria-label="Default select example"
                                        value={enterCategory} onChange={e=>setEnterCategory(e.target.value)}>
                                          
                                          <option selected>Category</option>
                                          <option value="Crockery">Crockery</option>
                                          <option value="Mobiles">Mobiles</option>
                                          <option value="Laptop">Laptop</option>
                                          <option value="Sarees">Sarees</option>
                                          <option value="Mobile Accessories">Mobile Accessories</option>
                                        </select>
                                        <div class="form-group">
                                            <label for="productprice">Subcategory:</label>
                                            <input type="text" class="form-control" id="subcategory"
                                                placeholder="Enter Sub category"
                                                value={enterSubCategory} onChange={e=>setEnterSubCategory(e.target.value)}/>
                                            <div class="invalid-feedback">enter subcategory</div>

                                        </div>
                                        <div class="form-group ">
                                            <label for="productprice">Inventory:</label>
                                            <input type="text" class="form-control" id="productprice"
                                                placeholder="Enter Inventory"
                                                value={enterInventory} onChange={e=>setEnterInventory(e.target.value)}/>
                                            <div class="invalid-feedback">Inventory:</div>

                                        </div>

                                        <div class="form-check">
                                        <input class="form-check-input" type="checkbox"  onChange={toggle} id="flexCheckDefault"/>
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Trending
                                        </label>
                                        </div>
                                        <p>Product Images:</p>
                                        <div class="custom-file">

                                            <input type="file" multiple class="custom-file-input my-3" id="productimage" required
                                            onChange={handleImageUpload}/>
                                            
                                            <label class="custom-file-label" for="productimage">Choose
                                                file...</label>
                                            <div class="invalid-feedback">File Format Not Supported</div>
                                        </div>
                                        {/* <p>Product Image 2:</p>
                                        <div class="custom-file">

                                            <input type="file" class="custom-file-input my-3" id="productimage" required
                                            onChange={e=>imageUpload[1]=(e.target.files[0])}/>
                                            
                                            <label class="custom-file-label" for="productimage">Choose
                                                file...</label>
                                            <div class="invalid-feedback">File Format Not Supported</div>
                                        </div>
                                        <p>Product Image 3:</p>
                                        <div class="custom-file">

                                            <input type="file" class="custom-file-input my-3" id="productimage" required
                                            onChange={e=>imageUpload[2]=(e.target.files[0])}/>
                                            
                                            <label class="custom-file-label" for="productimage">Choose
                                                file...</label>
                                            <div class="invalid-feedback">File Format Not Supported</div>
                                    </div>*/}
                                     
                                           
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-dark mt-5 mx-auto d-block"  onClick={handleChange}>Save
                                            New Details</button>  

    </section>
}
    </>
  )
}

export default EditProduct
