import { Helmet } from 'react-helmet';
import React, { useState } from 'react';
import { auth } from '../../firebase.config';
import {db, storage} from '../../firebase.config'
import { ref, uploadBytesResumable, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, updateDoc, doc, setDoc, getDoc,} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom';

export default function Contact() {

    const navigate= useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [mobile, setMobile] = useState('');
    const [isSolved, setIsSolved] = useState('Not Solved');

    const handleSubmit = async(event) => {
        event.preventDefault();

        const formData = { name, email, message, subject };
        let date = new Date().toJSON();
        console.log(date);
        try {
            const messageRef = doc(collection(db, "contact"));
            await setDoc(messageRef, {
              name: name,
              email: email,
              message: message,
              subject: subject,
              mobileNo: mobile,
              createdAt: date,
              Solved: isSolved,
              
            });
            alert("Message Sent");
        } catch (error) {
          alert('Error adding document ');
          console.log(error)
        }
        navigate("/home")

    }

    return (
        <>

            <Helmet>
                <title>Contact Us</title>
            </Helmet>

            <div class="breadcrumb_part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breadcrumb_iner">
                                <h2>Contact</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contact-section section_padding">
                <div class="container">
                {/*<div class="d-none d-sm-block mb-5 pb-4">
                    <div id="map" style="height: 480px;"></div>
                    <script>
                    function initMap() {
                        var uluru = {
                        lat: -25.363,
                        lng: 131.044
                        };
                        var grayStyles = [{
                            featureType: "all",
                            stylers: [{
                                saturation: -90
                            },
                            {
                                lightness: 50
                            }
                            ]
                        },
                        {
                            elementType: 'labels.text.fill',
                            stylers: [{
                            color: '#ccdee9'
                            }]
                        }
                        ];
                        var map = new google.maps.Map(document.getElementById('map'), {
                        center: {
                            lat: -31.197,
                            lng: 150.744
                        },
                        zoom: 9,
                        styles: grayStyles,
                        scrollwheel: false
                        });
                    }
                    </script>
                    <script
                    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDpfS1oRGreGSBU5HHjMmQ3o5NLw7VdJ6I&callback=initMap">
                    </script>

                </div>*/}


                <div class="row">
                    <div class="col-12">
                    <h2 class="contact-title">Get in Touch</h2>
                    </div>
                    <div class="col-lg-8">
                    <form class="form-contact contact_form" action="contact_process.php" method="post" id="contactForm"  onSubmit={handleSubmit}
                       >
                        <div class="row">
                       
                        <div class="col-sm-6">
                            <div class="form-group">
                            <input class="form-control" name="name" id="name" type="text" onfocus="this.placeholder = ''"
                                onblur="this.placeholder = 'Enter your name'" placeholder='Enter your name' onChange={(e) => setName(e.target.value)} required/>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                            <input class="form-control" name="email" id="email" type="email" onfocus="this.placeholder = ''"
                                onblur="this.placeholder = 'Enter email address'" placeholder='Enter email address' onChange={(e) => setEmail(e.target.value)} required/>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                            <input class="form-control" name="name" id="name" type="tel" onfocus="this.placeholder = ''"
                                onblur="this.placeholder = 'Enter phone number'" placeholder='Enter Phone Number' onChange={(e) => setMobile(e.target.value)} required/>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                            <input class="form-control" name="subject" id="subject" type="text" onfocus="this.placeholder = ''"
                                onblur="this.placeholder = 'Enter Subject'" placeholder='Enter Subject' onChange={(e) => setSubject(e.target.value)} required/>
                            </div>
                        </div>

                        
                        <div class="col-12">
                            <div class="form-group">

                            <textarea class="form-control w-100" name="message" id="message" cols="30" rows="9"
                                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'"
                                placeholder='Enter Message' onChange={(e) => setMessage(e.target.value)} required></textarea>
                            </div>
                        </div>
                        </div>
                        <div class="form-group mt-3">
                        <button href="#" class="btn_3 button-contactForm" type="submit">Send Message</button>
                        </div>
                    </form>
                    </div>
                    <div class="col-lg-4">
                    <div class="media contact-info">
                        <span class="contact-info__icon"><i class="ti-home"></i></span>
                        <div class="media-body">
                        <h3><a href="https://goo.gl/maps/DjCfJrjnXP4ad7R68" target="_blank" style={{color: 'inherit'}}>Door No. 103, Viceroy Residency.</a></h3>
                        <p> Bapuji Nagar, Hyderabad, Telangana 500011</p>
                        </div>
                    </div>
                    {/* <div class="media contact-info">
                        <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                        <div class="media-body">
                       <h3> <a href="tel:+919550929099" target="_blank" style={{color: 'inherit'}}>+91 95509 29099</a></h3>
                        <p>Mon to Fri 9am to 6pm</p>
                        </div>
                    </div> */}
                    <div class="media contact-info">
                        <span class="contact-info__icon"><i class="ti-email"></i></span>
                        <div class="media-body">
                        <h3><a href="mailto:info@shreemainaventures.com" style={{color: 'inherit'}} target="_blank">info@shreemainaventures.com</a></h3>
                        <p>Send us your query anytime!</p>
                        </div>
                    </div>
                    </div>
                </div>
                
                </div>
            </div>

        </>    
    )
}
