import { Helmet } from 'react-helmet';
import img1 from '../../assets/img/icon/feature_icon_1.svg'
import img2 from '../../assets/img/icon/feature_icon_2.svg'
import img3 from '../../assets/img/icon/feature_icon_3.svg'
import img4 from '../../assets/img/icon/feature_icon_4.svg'

export default function About() {
    return (
        <>

            <Helmet>
                <title>About Us</title>
            </Helmet>

            <div className="breadcrumb_part">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb_iner">
                                <h2>About</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about_us padding_top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="about_us_content">
                                <h5>Our Mission</h5>
                                <h3>At our e-commerce website, our mission is to provide our customers with an exceptional shopping experience by offering a diverse range of products such as crockery, mobiles, laptops, sarees, and mobile accessories. We strive to make our website user-friendly and easy to navigate, ensuring that our customers can find what they are looking for quickly and efficiently.</h3>
                                {/* <div className="about_us_video">
                                    <img src="img/about_us_video.png" alt="#" className="img-fluid" />
                                    <a className="about_video_icon popup-youtube" href="https://www.youtube.com/watch?v=DWHB6nTyKDI"></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="feature_part section_padding">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <div className="feature_part_tittle">
                                <h3>Your Online store for Laptop, Mobiles, Crockery and Saree Requirements.</h3>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="feature_part_content">
                                <p>Check out now at Best Prices a huge selection of latest and best mobile phones, laptops and crockery items from the world's leading brands like Borosil, Femora, HP, Dell, JBL etc.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-sm-6">
                            <div className="single_feature_part">
                                <img src={img1} alt="#" />
                                <h4>Credit Card Support</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single_feature_part">
                                <img src={img2} alt="#" />
                                <h4>Online Order</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single_feature_part">
                                <img src={img3} alt="#" />
                                <h4>Free Delivery</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single_feature_part">
                                <img src={img4} alt="#" />
                                <h4>Product with Gift</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="client_review">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="client_review_slider owl-carousel">
                                <div className="single_client_review">
                                    <div className="client_img">
                                        <img src="img/client.png" alt="#" />
                                    </div>
                                    <p>"Working in conjunction with humanitarian aid agencies, we have supported programmes to help alleviate human suffering.</p>
                                    <h5>- Micky Mouse</h5>
                                </div>
                                <div className="single_client_review">
                                    <div className="client_img">
                                        <img src="img/client_1.png" alt="#" />
                                    </div>
                                    <p>"Working in conjunction with humanitarian aid agencies, we have supported programmes to help alleviate human suffering.</p>
                                    <h5>- Micky Mouse</h5>
                                </div>
                                <div className="single_client_review">
                                    <div className="client_img">
                                        <img src="img/client_2.png" alt="#" />
                                    </div>
                                    <p>"Working in conjunction with humanitarian aid agencies, we have supported programmes to help alleviate human suffering.</p>
                                    <h5>- Micky Mouse</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>*/}

           
            
        </>
    )
}
