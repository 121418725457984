import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from "../../firebase.config"
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();

    const login = async(e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            
            setLoading(false);
            toast("Login Successful");
            navigate('/home');


        } catch (error) {
            setLoading(false);
            console.log(error);
            toast("Something Went Wrong");
        }
    }


    return (
        <>
            <div className="login_part section_padding ">
                <div className="container">
                    <div className="row align-items-center">
                    {
                        loading? <div className="text-center">
                        <h3>loading....</h3> 
                    </div> : <div></div> 
                    }
                        

                        <div className="col-lg-6 col-md-6">
                            <div className="login_part_form">
                                <div className="login_part_form_iner">
                                    <h3>Welcome Back ! <br/>
                                        Please Sign in now</h3>
                                    <form className="row contact_form" action="#" method="post" novalidate="novalidate">
                                        <div className="col-md-12 form-group p_star my-3">
                                            <input type="text" className="form-control" id="name" name="name" value={email} 
                                              onChange={e=>setEmail(e.target.value)}  placeholder="Username" />
                                        </div>
                                        <div className="col-md-12 form-group p_star my-3">
                                            <input type="password" className="form-control" id="password" name="password" value={password}
                                               onChange={e=>setPassword(e.target.value)} placeholder="Password" />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <div className="creat_account d-flex align-items-center">
                                                <input type="checkbox" id="f-option" name="selector" />
                                                <label for="f-option">Remember me</label>
                                            </div>
                                            <button onClick={login} type="submit" value="submit" className="btn_3">
                                                log in
                                            </button>
                                            <a className="lost_pass" href="#">forget password?</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="login_part_text text-center">
                                <div className="login_part_text_iner">
                                    <h2>New to our Shop?</h2>
                                    <p>Then what are you waiting for? Join us and buy all products at Best Prices</p>
                                    <Link to="/signup" className="btn_3">Create an Account</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}
