import React from 'react';
import { cartActions } from '../../redux/slices/cartSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import {db, storage} from '../../firebase.config';
import { ref, uploadBytesResumable, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, updateDoc, setDoc, doc, getDoc,} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom';
import { getFirestore, Timestamp } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import axios from 'axios';
// import Instamojo from 'instamojo/checkout';
// import Razorpay from 'razorpay';
import 'firebase/storage';
import 'firebase/firestore';


export default function Checkout() {

  

const dispatch= useDispatch()
  const itemDetails=[]; 
  const [enterFirstName, setEnterFirstName] = useState('')
  const [enterLastName, setEnterLastName] = useState('')
  const [enterCompany, setEnterComapany] = useState('')

  const [enterPhone, setEnterPhone] = useState('')
  const [enterEmail, setEnterEmail] = useState('')
  const [enterAddress, setEnterAddress] = useState('')
  const [enterCity, setEnterCity] = useState('')

  const [enterPostal, setEnterPostal] = useState('')
  const [enterShipTo, setEnterShipTo] = useState(null)
  const [enterTotal, setEnterTotal] = useState('')
 
  const [loading, setLoading] = useState(false);
  const [enterNotes, setEnterNotes] = useState('');
  const navigate = useNavigate()

    const totalQty = useSelector(state=>state.cart.totalQuantity);
    const totalAmount = useSelector(state=>state.cart.totalAmount);
    const cartItems =  useSelector(state => state.cart.cartItems);


  const [paymentUrl, setPaymentUrl] = useState('');

  const handlePayment = async (event) => {
    event.preventDefault();
    console.log("clicked")
    cartItems.map(function(element) {
      // create a new object for each element in the data
      let newObject = {
        name: element.productName,
        quantity: element.quantity,
       price: element.price,
      };
      
      // add the object to the array
      itemDetails.push(newObject);
    });
    
    // const formData = { name, email, message, subject };
  
    let date = new Date();
    var docRef 
    try {
        
      docRef = await addDoc(collection(db, 'orders'), {
          FirstName: enterFirstName,
          LastName: enterLastName,
          Company: enterCompany,
          Phone: enterPhone,
          Sum: totalAmount,
          Email: enterEmail,
          Address: enterAddress,
          
          City: enterCity,
         
          Pincode: enterPostal,
          itemDetails,
          Notes: enterNotes,
          createdAt: date,
          updatedAt: date,
          Status: 'notPaid',
          Payment_id: '',
          
        });
        console.log("Order saved to database");
        
    } catch (error) {
      console.log('Error adding document:- ');
      console.log(error);
    }
   
    setLoading(true);
    const response = await axios.post('https://smvapi.netlify.app/.netlify/functions/api/create-payment', {
      amount: totalAmount,
      purpose: 'Product Purchase',
      currency: "INR",
      buyer_name: `${enterFirstName} ${enterLastName}`,
      email: enterEmail,
      phone: enterPhone,
      redirect_url: `https://shreemainaventures.com/billing/${docRef.id}`,
      webhook_url: `https://smvapi.netlify.app/.netlify/functions/api/webhook?order_id=${docRef.id}`,
    }, { timeout: 10000 })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        setPaymentUrl(response.data);
        window.location.href = response.data;
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  
    const handleSubmit = async(event) => {

      event.preventDefault();
      const total= totalAmount;
      

      cartItems.map(function(element) {
        // create a new object for each element in the data
        let newObject = {
          name: element.productName,
          quantity: element.quantity,
         
        };
        
        // add the object to the array
        itemDetails.push(newObject);
      });
      
      // const formData = { name, email, message, subject };
      let date = new Date().toJSON();
      
      try {
          const messageRef = doc(collection(db, "orders"));
          await setDoc(messageRef, {
            FirstName: enterFirstName,
            LastName: enterLastName,
            Company: enterCompany,
            Phone: enterPhone,
            Sum: totalAmount,
            Email: enterEmail,
            Address: enterAddress,
            
            City: enterCity,
           
            Pincode: enterPostal,
            itemDetails,
            Notes: enterNotes,
            createdAt: date,
            updatedAt: date,
            Status: 'Ordered',
          
            
          });
          alert("Order Placed");
          
      } catch (error) {
        alert('Error adding document ');
        console.log(error);
      }
      dispatch(cartActions.emptyCart());
      navigate("/home")

  }
 

    return (
        <div>
          { loading ? <div className='container'>
            <h2>loading</h2>
            </div> : <div></div> }
            
            <section class="checkout_area section_padding">
    <div class="container">
     
      
      {/* <div class="cupon_area">
        <div class="check_title">
          <h2>
            Have a coupon?
            <a href="#">Click here to enter your code</a>
          </h2>
        </div>
        <input type="text" placeholder="Enter coupon code" />
        <a class="tp_btn" href="#">Apply Coupon</a>
      </div> */}
      <div class="billing_details">
        <div class="row">
          <div class="col-lg-8">
            <h3>Billing Details</h3>
            <form class="row contact_form needs-validation" onSubmit={handlePayment}>
              <div class="col-md-6 form-group">
                <input type="text" class="form-control" id="first" name="name"  placeholder="First name"
                 value={enterFirstName} onChange={e=>setEnterFirstName(e.target.value)} required
                 />
                {/* <span class="placeholder" data-placeholder="First name"></span> */}
              </div>
              <div class="col-md-6 form-group">
                <input type="text" class="form-control" id="last" name="name" placeholder="Last name" 
                 value={enterLastName} onChange={e=>setEnterLastName(e.target.value)} required
                />
                {/* <span class="placeholder" data-placeholder="Last name"></span> */}
              </div>
              <div class="col-md-12 form-group">
                <input type="text" class="form-control" id="company" name="company" placeholder="Company name"
                value={enterCompany} onChange={e=>setEnterComapany(e.target.value)} required
                />
              </div>
              <div class="col-md-6 form-group">
                <input type="text" class="form-control" id="number" name="number" placeholder="Phone number"
                value={enterPhone} onChange={e=>setEnterPhone(e.target.value)} required
                />
                {/* <span class="placeholder" data-placeholder="Phone number"></span> */}
              </div>
              <div class="col-md-6 form-group">
                <input type="text" class="form-control" id="email" name="compemailany" placeholder="Email Address" 
                value={enterEmail} onChange={e=>setEnterEmail(e.target.value)} required
                />
                {/* <span class="placeholder" data-placeholder="Email Address"></span> */}
              </div>
              {/* <div class="col-md-12 form-group p_star">
                <select class="country_select"
                value={enterCountry} onChange={e=>setEnterCountry(e.target.value)}
                >
                  <option value="1">Country</option>
                  <option value="2">Country</option>
                  <option value="4">Country</option>
                </select>
              </div> */}
              <div class="col-md-12 form-group">
                <input type="text" class="form-control" id="add1" name="add1" placeholder="Address" 
                value={enterAddress} onChange={e=>setEnterAddress(e.target.value)} required
                />
                {/* <span class="placeholder" data-placeholder="Address line 01"></span> */}
              </div>
              {/* <div class="col-md-12 form-group p_star">
                <input type="text" class="form-control" id="add2" name="add2" placeholder="Address line 02"
                value={enterAddress02} onChange={e=>setEnterAddress02(e.target.value)}
                /> */}
                {/* <span class="placeholder" data-placeholder="Address line 02"></span> */}
              {/* </div> */}
              <div class="col-md-12 form-group">
                <input type="text" class="form-control" id="city" name="city" placeholder="Town/City"
                value={enterCity} onChange={e=>setEnterCity(e.target.value)} required
                />
                {/* <span class="placeholder" data-placeholder="Town/City"></span> */}
              </div>
              {/* <div class="col-md-12 form-group p_star">
                <select class="country_select"
                value={enterDistrict} onChange={e=>setEnterDistrict(e.target.value)}
                >
                  <option value="1">District</option>
                  <option value="2">District</option>
                  <option value="4">District</option>
                </select>
              </div> */}
              <div class="col-md-12 form-group">
                <input type="text" class="form-control" id="zip" name="zip" placeholder="Pincode/ZIP" minlength="6" maxlength="6"
                value={enterPostal} onChange={e=>setEnterPostal(e.target.value)} required
                />
              </div>
             
              <div class="col-md-12 form-group">
                {/* <div class="creat_account">
                  <h3>Shipping Details</h3>
                  <input type="checkbox" id="f-option3" name="selector" 
                  value={enterShipTo} onChange={e=>setEnterShipTo(e.target.value)}/>
                  <label for="f-option3">Ship to a different address?</label>
                </div> */}
                
                <textarea class="form-control" name="message" id="message" rows="1"
                  placeholder="Order Notes" 
                  value={enterNotes} onChange={e=>setEnterNotes(e.target.value)}
                  ></textarea>
              </div>
              <button class="col-md-5 btn_3 ml-3"  type='submit' >Proceed to Payment</button>
            </form>
        
          </div>
          <div class="col-lg-4">
            <div class="order_box">
              <h2>Your Order</h2>
              <ul class="list">
                <li>
                  <a href={paymentUrl}>Product
                    <span>Total</span>
                  </a>
                </li>
                {/* <li>
                  <a href="#">Fresh Blackberry
                    <span class="middle">x 02</span>
                    <span class="last">$720.00</span>
                  </a>
                </li>
                <li>
                  <a href="#">Fresh Tomatoes
                    <span class="middle">x 02</span>
                    <span class="last">$720.00</span>
                  </a>
                </li>
                <li>
                  <a href="#">Fresh Brocoli
                    <span class="middle">x 02</span>
                    <span class="last">$720.00</span>
                  </a>
                </li> */}
              </ul>
              <ul class="list list_2">
                <li>
                  <a href="#">Subtotal
                    <span>₹{totalAmount}</span>
                  </a>
                </li>
                <li>
                  <a href="#">Shipping
                    <span>Flat rate: ₹00.00</span>
                  </a>
                </li>
                <li>
                  <a href="#">Total
                    <span>₹{totalAmount}</span>
                  </a>
                </li>
              </ul>
              {/* <div class="payment_item">
                <div class="radion_btn">
                  <input type="radio" id="f-option5" name="selector" />
                  <label for="f-option5">Check payments</label>
                  <div class="check"></div>
                </div>
                <p>
                  Please send a check to Store Name, Store Street, Store Town,
                  Store State / County, Store Postcode.
                </p>
              </div>
              <div class="payment_item active">
                <div class="radion_btn">
                  <input type="radio" id="f-option6" name="selector" />
                  <label for="f-option6">Paypal </label>
                  <img src="img/product/single-product/card.jpg" alt="" />
                  <div class="check"></div>
                </div>
                <p>
                  Please send a check to Store Name, Store Street, Store Town,
                  Store State / County, Store Postcode.
                </p>
              </div> */}
              {/* <div class="creat_account">
                <input type="checkbox" id="f-option4" name="selector" />
                <label for="f-option4">I’ve read and accept the </label>
                <a href="#">terms & conditions*</a>
              </div> */}
              {/* <a  href={paymentUrl}><button>pay</button></a> */}
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
        </div>
    )
}
