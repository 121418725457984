
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCnvDeousTDmcevu9RobZ1o1RErbSoHtVI",
  authDomain: "shree-mv.firebaseapp.com",
  projectId: "shree-mv",
  storageBucket: "shree-mv.appspot.com",
  messagingSenderId: "841273569711",
  appId: "1:841273569711:web:b0645194ff6bff689eff5a",
  measurementId: "G-FKJMYFZ252" 
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app); 

export default app;